require("turbolinks").start();

import $ from 'jquery';
import '@nathanvda/cocoon';
import 'gasparesganga-jquery-loading-overlay';
import axios from 'axios';
import Cookies from 'js-cookie/src/js.cookie';

window.$ = $;
window.jQuery = $;
global.$ = $;
global.jQuery = $;
window.Cookies = Cookies;
window.axios = axios;

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("../channels");
require("../packs/mylime");
require("../packs/mylime-select");
import '../packs/sort_table';
//import '../packs/nfc_reader'

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('.attachment-slider')) {
    import('../packs/slider').then(module => {
      module.initializeSlider();
    }).catch(error => {
      console.error('Error loaging slider:', error);
    });
  }
  if (document.querySelector('.sortable')) {
    import('../packs/rails_sortable').then(module => {
      module.initializeRailsSortable();
    }).catch(error => {
      console.error('Error loading railsSortable:', error);
    });
  }
  if (document.getElementById('view-table') && document.getElementById('view-cards')) {
    import('../packs/view_switcher').then(module => {
      module.initializeViewSwitcher();
    }).catch(error => {
      console.error('Errore nel caricamento di view_switcher:', error);
    });
  }

  if (document.querySelector("#user_phone_number")) {
    import('../packs/intl_tel_input').then(module => {
      module.initializeIntlTelInput();
    }).catch(error => {
      console.error('Error loading intlTelInput:', error);
    });
  }
  if (
    document.querySelector('.advanced_dropdown')
  ) {
    import('../packs/select2-initializer').then(module => {
      module.initializeSelect2();
    }).catch(error => {
      console.error('Error loading mylime-select:', error);
    });
  }
});